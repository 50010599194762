import React, { Dispatch, SetStateAction, useState } from 'react';
import { ExtendedTable } from '../../Table/ExtendedTable';
import { getData } from '../../../routes/portfolio/components/Content/ContentRule/data-table';
import { getColumns } from '../../../routes/portfolio/components/Content/ContentRule/columns';
import { TableManager } from '../../Table/TableManager';
import { ACTIONS_HEADER_ID } from '../../../routes/portfolio/components/OptimizationProposal/columns';
import { TableStyled } from '../../../routes/portfolio/styles/OptimizationProposal';
import { DataTableFilterOption } from '../../../routes/portfolio/components/Content/ContentRule/types';

import { NexoyaContentFilterFieldName, NexoyaContentFilterOperator, NexoyaContentV2 } from '../../../types';
import { getFilterValueInputBasedOnType } from '../../../routes/portfolio/components/Content/ContentRule/utils';
import { useFilteredContentsQuery } from '../../../graphql/portfolioRules/queryFilteredContents';
import { useTranslationsQuery } from '../../../graphql/translation/queryTranslations';
import { Skeleton } from '../../../components-ui/Skeleton';
import { getDevice } from '../../../utils/media';
import { toast } from 'sonner';

interface Props {
  filters: DataTableFilterOption[];
  providerId: number;
  accountId: number;
  portfolioId: number;
  shouldFetch?: boolean;
  excludePortfolioContents?: boolean;
  setShouldFetch?: Dispatch<SetStateAction<boolean>>;
  selectedContentIds: number[];
  setSelectedContentIds: Dispatch<SetStateAction<number[]>>;
}

function ContentSelectionTable({
  filters,
  providerId,
  accountId,
  portfolioId,
  shouldFetch,
  setShouldFetch,
  selectedContentIds,
  setSelectedContentIds,
  excludePortfolioContents,
}: Props) {
  const { isLaptop, isTablet, isDesktop, isDesktopL } = getDevice();

  const [filteredContents, setFilteredContents] = useState<NexoyaContentV2[]>([]);

  // Function to process and deduplicate filters
  const getDeduplicatedFilters = (filters: DataTableFilterOption[]) =>
    filters.filter(
      (filter, index, self) =>
        // Filter out empty filterValues or filterValues with empty strings
        filter?.filterValues?.length > 0 &&
        filter?.filterValues?.some((value) => value !== '') &&
        // Remove duplicates if the filter type is date
        (filter.type !== 'date' ||
          self.findIndex(
            (f) => f.type === filter.type && f.value === filter.value && f.filterOperator === filter.filterOperator,
          ) === index),
    );

  const deduplicatedFilters = getDeduplicatedFilters(filters);

  const { loading } = useFilteredContentsQuery({
    providerId,
    portfolioId,
    filters: [
      ...deduplicatedFilters.map((option) => ({
        fieldName: option.value as NexoyaContentFilterFieldName,
        operator: option.filterOperator as NexoyaContentFilterOperator,
        value: getFilterValueInputBasedOnType(option.type, option.filterValues),
      })),
      ...(accountId
        ? [
            {
              fieldName: NexoyaContentFilterFieldName.ParentContentId,
              operator: NexoyaContentFilterOperator.Eq,
              value: { number: accountId },
            },
          ]
        : []),
    ],
    excludePortfolioContents,
    skip: !shouldFetch,
    onCompleted: (data) => {
      setFilteredContents(data?.filterContents ?? []);
      setShouldFetch?.(false);
      setSelectedContentIds(data?.filterContents?.map((content) => content.contentId) || []);
    },
    onError: (error) => {
      console.error('Error fetching filtered contents:', error);
      toast.error('Error fetching filtered contents');
      setFilteredContents([]);
      setSelectedContentIds([]);
    },
  });

  const { data: translationsData } = useTranslationsQuery();
  const translations = translationsData?.translations || [];

  const tableData = loading
    ? createLoadingData(10)
    : getData({
        content: filteredContents,
        translations,
        portfolio: null,
        isContentIncluded: (contentId: number) => selectedContentIds.includes(contentId),
        includeContentId: (contentId: number) => setSelectedContentIds((ids) => [...ids, contentId]),
        excludeContentId: (contentId: number) => setSelectedContentIds((ids) => ids.filter((id) => id !== contentId)),
      });

  const contentWidth = isDesktopL ? 940 : isDesktop ? 300 : isLaptop ? 350 : isTablet ? 100 : 300;
  const columns = getProcessedColumns(loading, contentWidth);

  return (
    <TableStyled className="w-full max-w-[1998px]" maxHeight="90vh">
      <ExtendedTable
        tableId="content_table"
        disablePagination={false}
        disableManager={false}
        disableExpanded={false}
        data={tableData}
        columns={columns}
        renderTableManager={({
          columns,
          getToggleHideAllColumnsProps,
          toggleHideAllColumns,
          setStickyColumns,
          stickyColumns,
        }) => (
          <TableManager
            idsNotAllowedToHide={[ACTIONS_HEADER_ID, 'expander', 'content']}
            columns={columns}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            toggleHideAllColumns={toggleHideAllColumns}
            setStickyColumns={setStickyColumns}
            stickyColumns={stickyColumns}
            depth={1}
          />
        )}
      />
    </TableStyled>
  );
}

export default ContentSelectionTable;

const createLoadingData = (count: number) => Array(count).fill({});

const getProcessedColumns = (loading: boolean, contentWidth: number) => {
  const baseColumns = getColumns({ contentWidth });
  if (loading) {
    return baseColumns.map((column) => ({
      ...column,
      columns: column.columns?.map((subColumn) => ({
        ...subColumn,
        Cell: () => <Skeleton className="h-5 w-full" />,
      })),
    }));
  }
  return baseColumns;
};
