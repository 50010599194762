import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { ProviderDropdownFilter } from '../../routes/portfolio/components/Content/ContentRule/filter/ProviderDropdownFilter';
import { FilterItem } from '../../routes/portfolio/components/Content/ContentRule/filter/FilterItem';
import { AddFilterDropdown } from '../../routes/portfolio/components/Content/ContentRule/filter/AddFilterDropdown';
import { DataTableFilterOption } from '../../routes/portfolio/components/Content/ContentRule/types';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'sonner';
import { AVAILABLE_FIELDS_AND_OPERATIONS_QUERY } from '../../graphql/portfolioRules/queryAvailableFieldsAndOperations';
import NoSelection from '../../components-ui/NoSelection';
import { Button as ShadcnButton } from '../../components-ui/Button';

import SvgInfo from '../icons/Info';
import { nexyColors } from '../../theme';
import {
  CONTENT_TYPE_CAMPAIGN_DEFAULT_VALUE,
  getFilterValuesFromFilter,
  getIconForField,
  getTypeForField,
  humanizeFieldName,
  mapOperationsToUI,
} from '../../routes/portfolio/components/Content/ContentRule/utils';
import {
  NexoyaContentFilter,
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaFieldOperation,
} from '../../types';
import { useRouteMatch } from 'react-router';
import ContentSelectionTableV2 from './components/ContentSelectionTableV2';
import Switch from '../Switch';
import { v4 as uuidv4 } from 'uuid';
import { camelCase, startCase } from 'lodash';

interface Props {
  setSelectedContentIds: Dispatch<SetStateAction<number[]>>;
  selectedProviderId: number;
  setSelectedProviderId: Dispatch<SetStateAction<number>>;
  selectedAccountId: number;
  setSelectedAccountId: Dispatch<SetStateAction<number>>;
  selectedContentIds: number[];
  selectedOptions: DataTableFilterOption[];
  setSelectedOptions: Dispatch<SetStateAction<DataTableFilterOption[]>>;
  pendingFilters: NexoyaContentFilter[];
  setPendingFilters: Dispatch<SetStateAction<NexoyaContentFilter[]>>;
}

function ContentSelection({
  setSelectedContentIds,
  selectedProviderId,
  setSelectedProviderId,
  selectedAccountId,
  setSelectedAccountId,
  selectedContentIds,
  selectedOptions,
  setSelectedOptions,
  pendingFilters,
  setPendingFilters,
}: Props) {
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const [excludePortfolioContents, setExcludePortfolioContents] = useState(false);

  const [shouldFetch, setShouldFetch] = useState(false);

  const [fields, setFields] = useState([]);
  const [openCombobox, setOpenCombobox] = useState(false);

  const [loadAvailableFieldsAndOperations, { loading: loadingAvailableFieldsAndOperations }] = useLazyQuery(
    AVAILABLE_FIELDS_AND_OPERATIONS_QUERY,
    {
      onError: (error) => {
        toast.error('Failed to load the available fields and operators');
        console.error(error);
      },
    },
  );

  useEffect(() => {
    if (selectedProviderId) {
      setShouldFetch(true);

      loadAvailableFieldsAndOperations({ variables: { providerId: selectedProviderId } })
        .then((res) => {
          const availableFields = res.data?.availableFieldOperations ?? [];
          const contentTypeField: NexoyaFieldOperation = availableFields.find(
            (field: NexoyaFieldOperation) => field.fieldName === NexoyaContentFilterFieldName.ContentType,
          );
          if (contentTypeField) {
            // Default to content type field if it exists as a requirement from CSM
            setSelectedOptions([
              {
                id: uuidv4(),
                label: humanizeFieldName(contentTypeField.fieldName),
                icon: getIconForField(contentTypeField.fieldName),
                value: contentTypeField.fieldName,
                options:
                  contentTypeField.allowed.enumOptionsString?.map((option: string) => ({
                    label: startCase(camelCase(option)),
                    value: option,
                  })) || [],
                type: getTypeForField(contentTypeField.allowed),
                filterValues: [CONTENT_TYPE_CAMPAIGN_DEFAULT_VALUE],
                filterOperator: NexoyaContentFilterOperator.Eq,
                operators: mapOperationsToUI(contentTypeField.operators),
              },
            ]);
          } else {
            setSelectedOptions([]);
          }
          // Reset only when providerId changes
          setFields(
            availableFields.map((field: any) => {
              const options =
                field.allowed.fieldType === 'boolean'
                  ? [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]
                  : field.allowed.fieldType === 'string'
                    ? field.allowed.enumOptionsString?.map((option: string) => ({
                        label: startCase(camelCase(option)),
                        value: option,
                      })) || []
                    : field.allowed.enumOptionsNumber?.map((option: number) => ({
                        label: String(option),
                        value: String(option),
                      })) || [];

              // Map incoming operations to UI-friendly operators
              const operators = mapOperationsToUI(field.operators);

              return {
                label: humanizeFieldName(field.fieldName),
                value: field.fieldName,
                options,
                operators,
                icon: getIconForField(field.fieldName),
                type: getTypeForField(field.allowed),
                placeholder: `Filter by ${humanizeFieldName(field.fieldName)}`,
              };
            }),
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedProviderId]);

  useEffect(() => {
    if (selectedProviderId) {
      const newSelectedOptions: DataTableFilterOption[] = pendingFilters
        ?.map((filter) => {
          const field = fields.find((f) => f.value === filter.fieldName);

          if (!field) {
            return null;
          }

          return {
            id: uuidv4(),
            label: field.label,
            icon: field.icon,
            value: filter.fieldName,
            options: field.options,
            type: field.type,
            filterValues: getFilterValuesFromFilter(filter),
            filterOperator: filter.operator,
            operators: field.operators,
          };
        })
        .filter(Boolean); // Remove null entries

      if (newSelectedOptions.length) {
        setSelectedOptions(newSelectedOptions);
      }
      setShouldFetch(true);
    }
  }, [selectedProviderId, pendingFilters, fields]);

  return (
    <>
      <div className="flex flex-col gap-6 p-6 pr-0">
        <div className="flex max-w-xl flex-col gap-1">
          <h4 className="text-base font-medium text-neutral-900">Start your filter selection</h4>
          <p className="text-sm font-light text-neutral-500">
            New contents matching your filter will be automatically detected for you to quickly add into your portfolio
          </p>
        </div>
        {/* TODO: Fix this w-5/6, for w/e reason whenever the table appears this stretches outside its boundaries, making it look weird */}
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-2">
            <ProviderDropdownFilter
              portfolioId={portfolioId}
              selectedProviderId={selectedProviderId}
              setSelectedProviderId={setSelectedProviderId}
              selectedAccountId={selectedAccountId}
              setSelectedAccountId={setSelectedAccountId}
              setShouldFetch={setShouldFetch}
              allowMultipleProviders={false}
            />
            {/*{allowMultipleProviders && selectedProviderId ? (*/}
            {/*  selectedProviderId?.map((providerId) => (*/}
            {/*    <ProviderDropdownFilter*/}
            {/*      key={providerId}*/}
            {/*      portfolioId={portfolioId}*/}
            {/*      selectedProviderId={providerId}*/}
            {/*      setSelectedProviderId={setSelectedProviderId}*/}
            {/*      selectedAccountId={selectedAccountId}*/}
            {/*      setSelectedAccountId={setSelectedAccountId}*/}
            {/*      setShouldFetch={setShouldFetch}*/}
            {/*    />*/}
            {/*  )*/}
            {/*) :             <ProviderDropdownFilter*/}
            {/*  portfolioId={portfolioId}*/}
            {/*  selectedProviderId={selectedProviderId}*/}
            {/*  setSelectedProviderId={setSelectedProviderId}*/}
            {/*  selectedAccountId={selectedAccountId}*/}
            {/*  setSelectedAccountId={setSelectedAccountId}*/}
            {/*  setShouldFetch={setShouldFetch}*/}
            {/*  allowMultipleProviders={allowMultipleProviders}*/}
            {/*/>}*/}
            <div className="mx-3 my-auto h-8 w-[1px] bg-neutral-100" />
            {selectedOptions.map((selectedOption) => (
              <FilterItem
                key={selectedOption.id}
                selectedOptions={selectedOptions}
                selectedOption={selectedOption}
                setSelectedOptions={setSelectedOptions}
                defaultOpen={openCombobox}
                setShouldFetch={setShouldFetch}
              />
            ))}
            <AddFilterDropdown
              disabled={!selectedProviderId || fields.length === 0 || loadingAvailableFieldsAndOperations}
              options={fields}
              setSelectedOptions={setSelectedOptions}
              onSelect={() => setOpenCombobox(true)}
            />
          </div>
          <div className="flex h-8 gap-2">
            {selectedOptions.length ? (
              <ShadcnButton
                className="h-fit whitespace-pre py-1.5"
                onClick={() => {
                  setSelectedOptions([]);
                  setPendingFilters([]);
                  setShouldFetch(true);
                }}
                variant="ghost"
              >
                Reset
              </ShadcnButton>
            ) : null}
            <div className="mr-0.5 flex items-center gap-2">
              <span className="text-sm font-light text-neutral-700">Include existing portfolio contents</span>
              <Switch
                onToggle={() => {
                  setExcludePortfolioContents((prevState) => !prevState);
                  setShouldFetch(true);
                }}
                isOn={!excludePortfolioContents}
              />
            </div>
          </div>
        </div>

        {!selectedProviderId ? (
          <NoSelection
            icon={<SvgInfo style={{ color: nexyColors.azure, width: 14, height: 14 }} />}
            title="Select a channel to get started"
            subtitle="In order to start creating your filters, you will need to select the channel first"
          />
        ) : (
          <div className="mb-14">
            <ContentSelectionTableV2
              portfolioId={portfolioId}
              providerId={selectedProviderId}
              accountId={selectedAccountId}
              filters={selectedOptions}
              excludePortfolioContents={excludePortfolioContents}
              setSelectedContentIds={setSelectedContentIds}
              selectedContentIds={selectedContentIds}
              shouldFetch={shouldFetch}
              setShouldFetch={setShouldFetch}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ContentSelection;
