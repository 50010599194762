import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { NexoyaContentFilterInput } from '../../types';
import { IMPACT_GROUP_RULES_QUERY } from './queryImpactGroupRules';
import { useTeam } from '../../context/TeamProvider';

const UPDATE_IMPACT_GROUP_MUTATION = gql`
  mutation UpdateImpactGroupRule(
    $filters: [ContentFilterInput!]!
    $name: String
    $portfolioId: Float!
    $impactGroupRuleId: Float!
    $teamId: Float!
    $impactGroupId: Float
  ) {
    updateImpactGroupRule(
      impactGroupRuleId: $impactGroupRuleId
      filters: $filters
      portfolioId: $portfolioId
      teamId: $teamId
      impactGroupId: $impactGroupId
    ) {
      name
    }
  }
`;

type UpdateImpactGroupRuleProps = {
  teamId: number;
  portfolioId: number;
  impactGroupRuleId: number;
  filters: NexoyaContentFilterInput[];
  impactGroupId: number;
};

export function useUpdateImpactGroupRuleMutation({ portfolioId }) {
  const { teamId } = useTeam();

  return useMutation<
    {
      updateImpactGroupRule: {
        id: number;
        name: string;
        portfolioId: number;
        impactGroupRuleId: number;
        teamId: number;
      };
    },
    UpdateImpactGroupRuleProps
  >(UPDATE_IMPACT_GROUP_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      toast.success(`Impact group ${data.updateImpactGroupRule.name} updated successfully`);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: IMPACT_GROUP_RULES_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}
