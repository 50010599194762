import { create } from 'zustand';

interface DiscoveredContentStore {
  filteredContents: any[]; // Replace `any` with the appropriate type if known
  selectedContentIds: number[];
  discoveredContentsActiveSwitch: string;
  setFilteredContents: (contents: any[]) => void;
  addSelectedContentId: (id: number) => void;
  removeSelectedContentId: (id: number) => void;
  setDiscoveredContentsActiveSwitch: (switchId: string) => void;
}

export const useDiscoverContentsStore = create<DiscoveredContentStore>((set) => ({
  filteredContents: [],
  selectedContentIds: [],
  discoveredContentsActiveSwitch: 'to-review', // Default section

  setFilteredContents: (contents) =>
    set(() => ({
      filteredContents: contents,
    })),

  addSelectedContentId: (id) =>
    set((state) => ({
      selectedContentIds: [...state.selectedContentIds, id],
    })),

  removeSelectedContentId: (id) =>
    set((state) => ({
      selectedContentIds: state.selectedContentIds.filter((contentId) => contentId !== id),
    })),

  setDiscoveredContentsActiveSwitch: (switchId) =>
    set(() => ({
      discoveredContentsActiveSwitch: switchId,
    })),
}));
