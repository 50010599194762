import { gql, useQuery } from '@apollo/client';
import { useTeam } from 'context/TeamProvider';
import { NexoyaContentRule } from '../../types';

export interface NexoyaImpactGroupRule extends NexoyaContentRule {
  impactGroupRuleId: number;
  providerIds: number[];
  adAccountIds: number[];
}

export const IMPACT_GROUP_RULES_QUERY = gql`
  query ImpactGroupRules($portfolioId: Int!, $teamId: Int!) {
    portfolioV2(portfolioId: $portfolioId, teamId: $teamId) {
      impactGroupRule {
        impactGroupRuleId
        name
        providerId
        adAccountId
        contents {
          contentId
        }
        filters {
          fieldName
          operator
          value {
            date
            number
            numberArr
            string
            stringArr
          }
        }
      }
    }
  }
`;
type ImpactGroupRuleQueryVariables = {
  teamId: number;
  portfolioId: number;
};

export function useImpactGroupRuleQuery({ portfolioId }: { portfolioId: number }) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      portfolioV2: {
        impactGroupRules: NexoyaImpactGroupRule[];
      };
    },
    ImpactGroupRuleQueryVariables
  >(IMPACT_GROUP_RULES_QUERY, {
    variables: {
      teamId,
      portfolioId,
    },
  });

  return query;
}
