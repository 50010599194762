import { NexoyaContentRule, NexoyaFunnelStepV2, NexoyaProvider } from '../../../types';
import Typography from '../../../components/Typography';
import { nexyColors } from '../../../theme';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import ButtonIcon from '../../../components/ButtonIcon';
import SvgEllipsisV from '../../../components/icons/EllipsisV';
import AvatarProvider from '../../../components/AvatarProvider';
import translate from '../../../utils/translate';
import { capitalize, truncate } from 'lodash';
import Tooltip from '../../../components/Tooltip';
import {
  displayFilterValue,
  getIconForField,
  humanizeFieldName,
  OPERATORS_MAP,
} from '../components/Content/ContentRule/utils';
import { LabelLight } from '../../../components/InputLabel/styles';
import { ContentMetricAssignment } from '../components/Content/ContentMetricAssignment';
import React from 'react';
import { useProviders } from '../../../context/ProvidersProvider';
import { useTranslationsQuery } from '../../../graphql/translation/queryTranslations';
import { useSidePanelState } from '../../../components/SidePanel';

interface Props {
  contentRule: NexoyaContentRule;
  handleEditContentRule: (contentRule: NexoyaContentRule) => void;
  funnelSteps: NexoyaFunnelStepV2[];
}

export const ContentRuleCard = ({ contentRule, handleEditContentRule, funnelSteps }: Props) => {
  const { activeProviders } = useProviders();
  const { data: translationData } = useTranslationsQuery();

  const translations = translationData?.translations ?? [];
  const { isOpen: isOpenAssignMetrics, toggleSidePanel: toggleAssignMetricsSidepanel } = useSidePanelState();

  const getSelectedProvider = (providerId: number) =>
    activeProviders.find((provider: NexoyaProvider) => provider.provider_id === providerId);

  return (
    <div className="rounded-[5px] border border-neutral-100" key={contentRule.contentRuleId}>
      <div className="flex items-center justify-between rounded-t-[5px] border-b border-neutral-100 bg-seasalt p-4">
        <Typography style={{ color: nexyColors.neutral900 }} variant="h3">
          {contentRule.name}
        </Typography>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ButtonIcon
              style={{
                fontSize: 18,
              }}
            >
              <SvgEllipsisV />
            </ButtonIcon>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="font-normal" align="end">
            <DropdownMenuItem onSelect={() => handleEditContentRule(contentRule)}>Edit filters</DropdownMenuItem>
            <DropdownMenuItem onSelect={() => toggleAssignMetricsSidepanel()}>Edit metrics</DropdownMenuItem>
            <DropdownMenuItem
            // Implement duplication logic if needed
            >
              Duplicate
            </DropdownMenuItem>
            <DropdownMenuItem
            // Implement deletion logic if needed
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="p-3">
        <div className="mb-4 text-neutral-500">Channel and filter selection</div>
        <div className="flex gap-2">
          <div className="w-fit gap-1.5 truncate rounded-[5px] border border-[#D2D3DA] px-3 py-1.5">
            <div className="flex items-center gap-3">
              <AvatarProvider variant="circle" providerId={contentRule.providerId} size={16} color="dark" />
              {translate(translations, getSelectedProvider(contentRule.providerId)?.name)}
              {contentRule.adAccountId ? (
                <>
                  :
                  <span className="text-neutral-400">
                    {truncate(contentRule.adAccountId?.toString(), { length: 20 })}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          {contentRule.filters.map((filter) => (
            <div
              key={`${filter.fieldName}-${filter.operator}-${filter.value}`}
              className="w-fit gap-1.5 truncate rounded-[5px] border border-[#D2D3DA] px-3 py-1.5"
            >
              <Tooltip
                popperProps={{
                  style: { zIndex: 38000 },
                  modifiers: {
                    offset: {
                      offset: '0, 14',
                    },
                  },
                }}
                variant="dark"
                size="small"
                placement="top"
                content={`${capitalize(humanizeFieldName(filter.fieldName))} ${
                  OPERATORS_MAP[filter.operator]?.humanReadable
                }: ${displayFilterValue(filter)}`}
              >
                <div>
                  <span className="mr-2 text-neutral-400">{getIconForField(filter.fieldName)}</span>
                  <span className="font-light capitalize">{humanizeFieldName(filter.fieldName)}</span>
                  <>
                    <span className="font-light">: </span>
                    <span className="font-light capitalize text-muted-foreground">{displayFilterValue(filter)}</span>
                  </>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      <div className="p-3">
        <div className="mb-4 text-neutral-500">Metric assignment</div>
        <div className="flex gap-8">
          {funnelSteps?.map((funnelStep) => (
            <div key={funnelStep.funnelStepId} className="w-fit gap-1.5 truncate">
              <div>
                <LabelLight>{funnelStep.title}</LabelLight>
                <Tooltip
                  popperProps={{
                    style: { zIndex: 38000 },
                    modifiers: {
                      offset: {
                        offset: '0, 14',
                      },
                    },
                  }}
                  variant="dark"
                  size="small"
                  placement="bottom"
                  content={`Assigned metric for ${capitalize(funnelStep.title)}`}
                >
                  <span className="font-light">No metric</span>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isOpenAssignMetrics ? (
        <ContentMetricAssignment
          funnelSteps={funnelSteps}
          isOpen={isOpenAssignMetrics}
          toggleSidePanel={toggleAssignMetricsSidepanel}
          accountId={contentRule.adAccountId}
          providerId={contentRule.providerId}
        />
      ) : null}
    </div>
  );
};
