import { gql, useQuery } from '@apollo/client';
import { useTeam } from 'context/TeamProvider';
import { NexoyaContentRule } from '../../types';

export const CONTENT_GROUP_QUERY = gql`
  query ContentRules($portfolioId: Int!, $teamId: Int!) {
    portfolioV2(portfolioId: $portfolioId, teamId: $teamId) {
      contentRules {
        contentRuleId
        name
        providerId
        adAccountId
        contents {
          contentId
        }
        filters {
          fieldName
          operator
          value {
            date
            number
            numberArr
            string
            stringArr
          }
        }
      }
    }
  }
`;
type ContentRuleQueryVariables = {
  teamId: number;
  portfolioId: number;
};

export function useContentRuleQuery({ portfolioId }: { portfolioId: number }) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      portfolioV2: {
        contentRules: NexoyaContentRule[];
      };
    },
    ContentRuleQueryVariables
  >(CONTENT_GROUP_QUERY, {
    variables: {
      teamId,
      portfolioId,
    },
  });

  return query;
}
