import React, { FC, useEffect, useState } from 'react';
import { capitalize, concat, toNumber } from 'lodash';
import { FunnelContainerStyled, FunnelStepsContainerStyled, LabelsContainerStyled } from '../Funnel/styles';
import { FunnelSteps } from '../Funnel/components/FunnelSteps';
import { FunnelData } from '../Funnel/MultiSeriesFunnel';
import { AssignMetricFunnelStepLabel } from './ContentRule/assignment/AssignMetricFunnelStepLabel';
import { NexoyaFunnelStepMapping, NexoyaFunnelStepV2, NexoyaMeasurement } from '../../../../types';
import { usePortfolio } from '../../../../context/PortfolioProvider';
import { useMeasurementsQuery } from '../../../../graphql/measurement/queryMeasurements';
import { useTranslationsQuery } from '../../../../graphql/translation/queryTranslations';
import { useListConversionsQuery } from '../../../../graphql/portfolioRules/queryConversions';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components-ui/Popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../components-ui/Command';
import translate from '../../../../utils/translate';
import { nexyColors } from '../../../../theme';
import RadioGroup from '../../../../components/RadioGroup';
import FormControlLabel from '../../../../components/FormControlLabel';
import Radio from '../../../../components/Radio';
import { UTMTracking } from './ContentRule/assignment/UTMTracking';
import { cn } from '../../../../lib/utils';
import SidePanel, { SidePanelActions } from '../../../../components/SidePanel';
import ButtonAsync from '../../../../components/ButtonAsync';
import Button from '../../../../components/Button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../components-ui/AlertDialog';
import { useDialogState } from '../../../../components/Dialog';
import { LabelLight } from '../../../../components/InputLabel/styles';
import { getAssignedMetricBasedOnMappingType } from './ContentRule/utils';

const GA4_PROVIDER_ID = 44;

const DEFAULT_COLOR = nexyColors.azure;
const INITIAL_DATA: FunnelData = {
  labels: [],
  subLabels: [],
  values: [],
  colors: [],
};

export type MappingType = 'assign_metric' | 'custom_conversions' | 'utm_tracking' | 'ignore_mapping';

export const ASSIGN_METRIC_OPTIONS = (customConversionsDisabled: boolean) => [
  { value: 'assign_metric', label: 'Assign metric', disabled: false },
  { value: 'custom_conversions', label: 'Custom conversions', disabled: customConversionsDisabled },
  { value: 'ignore_mapping', label: 'Ignore mapping', disabled: false },
  {
    value: 'utm_tracking',
    label: 'UTM tracking',
    disabled: false,
  },
];

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  providerId: number;
  accountId: number;
  isOpen: boolean;
  toggleSidePanel: () => void;
}

export const ContentMetricAssignment: FC<Props> = ({ isOpen, toggleSidePanel, funnelSteps, providerId, accountId }) => {
  const [funnelData, setFunnelData] = useState<FunnelData>(INITIAL_DATA);
  const [assignedMetrics, setAssignedMetrics] = useState<(NexoyaFunnelStepMapping & { mappingType?: MappingType })[]>(
    [],
  );
  const { selectedFunnelStep, setSelectedFunnelStep } = usePortfolio().selectedFunnelStep;
  const selectedFunnelStepId = selectedFunnelStep?.funnel_step_id;

  const { data: measurementData } = useMeasurementsQuery({ providerId });
  const { data: GA4MeasurementData } = useMeasurementsQuery({ providerId: GA4_PROVIDER_ID });
  const { data: translationData } = useTranslationsQuery();

  const { data: listConversionsData } = useListConversionsQuery({
    providerId,
    adAccountContentId: accountId,
    skip: !accountId,
  });

  const {
    isOpen: isOpenApplyDialog,
    toggleDialog: toggleApplyDialog,
    closeDialog: closeApplyDialog,
  } = useDialogState();

  const translations = translationData?.translations || [];
  const conversions = listConversionsData?.listConversions;
  const measurements: Partial<NexoyaMeasurement[]> = measurementData?.measurements;
  const GA4Measurements: Partial<NexoyaMeasurement[]> = GA4MeasurementData?.measurements;

  const mergedMeasurements = concat(measurements, GA4Measurements);

  const isCustomConversionsOptionDisabled = !accountId || !conversions?.length;

  useEffect(() => {
    setFunnelData(
      funnelSteps?.reduce(
        (acc, step) => ({
          labels: [...acc.labels, step.title],
          subLabels: [...acc.subLabels, step.title],
          values: [...acc.values, [100]],
          colors: [...acc.colors, DEFAULT_COLOR],
        }),
        INITIAL_DATA,
      ),
    );
  }, [funnelSteps]);

  useEffect(() => {
    if (selectedFunnelStepId == null) return;

    setAssignedMetrics((prevState) => {
      if (prevState.some((metric) => metric.funnelStepId === selectedFunnelStepId)) return prevState;

      return [
        ...prevState,
        { funnelStepId: selectedFunnelStepId, metricId: null, conversionExternalIdentifierHash: null },
      ];
    });
  }, [selectedFunnelStepId, setAssignedMetrics]);

  const handleSubmit = () => {};

  const handleMetricTypeChange = (optionValue: MappingType) => {
    if (!selectedFunnelStepId) return;

    setAssignedMetrics((prevState) =>
      prevState.map((metric) =>
        metric.funnelStepId === selectedFunnelStepId
          ? {
              ...metric,
              mappingType: optionValue,
              ...(optionValue === 'ignore_mapping' ? { metricId: null, conversionExternalIdentifierHash: null } : {}),
            }
          : metric,
      ),
    );
  };

  const getSelectedMeasurementForFunnelStepId = (funnelStepId: number, measurements: Partial<NexoyaMeasurement[]>) =>
    measurements?.find(
      (m) => m?.measurement_id === assignedMetrics.find((a) => a.funnelStepId === funnelStepId)?.metricId,
    );

  const renderSelectConversions = (triggerClassName?: string) => (
    <Select
      value={
        conversions
          ?.find(
            (c) =>
              c.externalIdentifierHash ===
              assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.conversionExternalIdentifierHash,
          )
          ?.externalIdentifierHash?.toString() || undefined
      }
      onValueChange={(externalIdentifierHash) => {
        setAssignedMetrics((prevState) =>
          prevState.map((metric) =>
            metric.funnelStepId === selectedFunnelStepId
              ? { ...metric, conversionExternalIdentifierHash: externalIdentifierHash }
              : metric,
          ),
        );
      }}
    >
      <SelectTrigger className={cn('w-52 border-neutral-100 bg-white p-2 shadow-sm', triggerClassName)}>
        <SelectValue placeholder="Select a conversion goal" />
      </SelectTrigger>
      <SelectContent>
        {conversions?.map((conversion) => (
          <SelectItem key={conversion.externalIdentifierHash} value={conversion.externalIdentifierHash?.toString()}>
            <span>{translate(translations, conversion.title)}</span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
  const getNextOrPreviousFunnelStep = (nop: 'next' | 'previous') => {
    const funnelStepIndex = funnelSteps.findIndex((funnelStep) => funnelStep.funnelStepId === selectedFunnelStepId);
    return funnelSteps[nop === 'next' ? funnelStepIndex + 1 : funnelStepIndex - 1];
  };

  const renderAssignedMetricCombobox = (measurements: Partial<NexoyaMeasurement>[], triggerClassName?: string) => (
    <Popover>
      <PopoverTrigger asChild>
        <div
          className={cn(
            'w-52 whitespace-pre rounded-md border border-neutral-100 bg-white p-2 shadow-sm',
            triggerClassName,
          )}
        >
          <span className="block truncate">
            {translate(translations, getSelectedMeasurementForFunnelStepId(selectedFunnelStepId, measurements)?.name) ??
              'Select a metric'}
          </span>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput placeholder="Search metrics..." />
          <CommandList>
            <CommandEmpty>No metrics found.</CommandEmpty>
            <CommandGroup>
              {measurements
                ?.filter((measurement) => measurement.optimization_target_type?.includes(selectedFunnelStep?.type))
                ?.map((measurement) => (
                  <CommandItem
                    key={measurement.measurement_id}
                    value={translate(translations, measurement.name)}
                    onSelect={() => {
                      setAssignedMetrics((prevState) =>
                        prevState.map((metric) =>
                          metric.funnelStepId === selectedFunnelStepId
                            ? { ...metric, metricId: toNumber(measurement.measurement_id) }
                            : metric,
                        ),
                      );
                    }}
                  >
                    <span>{translate(translations, measurement.name)}</span>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );

  const renderInputsBasedOnMappingType = (mappingType?: MappingType) => {
    if (!mappingType) return null;

    if (mappingType === 'assign_metric') {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-900">Assign a metric</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Assign a metric for the conversion goal selected above.
            </div>
          </div>
          {renderAssignedMetricCombobox(measurements)}
        </div>
      );
    }

    if (mappingType === 'custom_conversions') {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-700">Select conversion goal</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Select a conversion goal as defined from this content rule’s selected channel.
            </div>
          </div>
          {renderSelectConversions()}
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-900">Assign a metric</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Assign a metric for the conversion goal selected above.
            </div>
          </div>
          {renderAssignedMetricCombobox(measurements)}
        </div>
      );
    }

    if (mappingType === 'utm_tracking') {
      return (
        <UTMTracking
          assignedMetrics={assignedMetrics}
          setAssignedMetrics={setAssignedMetrics}
          renderAssignedMetricCombobox={(triggerClassName) =>
            renderAssignedMetricCombobox(GA4Measurements, triggerClassName)
          }
          funnelStepId={selectedFunnelStepId}
          selectedMetricId={assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.metricId}
          selectedConversionId={
            assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.conversionExternalIdentifierHash
          }
        />
      );
    }

    return null;
  };

  const selectedMappingType = assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.mappingType;

  return (
    <>
      <SidePanel
        isOpen={isOpen}
        onClose={toggleSidePanel}
        paperProps={{ style: { width: 'calc(100% - 218px)', paddingBottom: '78px' } }}
      >
        <div className="border border-b-[#eaeaea] px-6 py-5">
          <h3 className="text-xl font-medium text-neutral-900">Assign metrics to funnel steps</h3>
        </div>
        <div className="flex h-full gap-10 px-6">
          <div className="w-fit flex-col">
            <div className="mb-8 mt-6 text-sm font-light text-neutral-500">
              Configure the metric mappings for each funnel step.
            </div>
            <FunnelContainerStyled>
              <LabelsContainerStyled>
                <AssignMetricFunnelStepLabel
                  assignedMetrics={assignedMetrics}
                  getSelectedMeasurementForFunnelStepId={(funnelStepId: number) =>
                    getSelectedMeasurementForFunnelStepId(funnelStepId, mergedMeasurements)
                  }
                  funnelSteps={funnelSteps}
                  translations={translations}
                />
              </LabelsContainerStyled>
              <FunnelStepsContainerStyled>
                <FunnelSteps withTooltip={false} funnelData={funnelData} funnelSteps={funnelSteps} />
              </FunnelStepsContainerStyled>
            </FunnelContainerStyled>
          </div>
          <div className="h-full w-[1px] bg-neutral-100" />
          <div className="flex h-full flex-col gap-6">
            <div>
              <div className="mt-6 text-lg text-neutral-700">
                Mapping type for <span className="font-semibold">{capitalize(selectedFunnelStep?.title)}</span>
              </div>
              <div className="mt-2 text-sm font-light text-neutral-500">
                Assign metrics to funnel steps to track the performance of your marketing campaigns.
              </div>

              <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
                {ASSIGN_METRIC_OPTIONS(isCustomConversionsOptionDisabled).map((option) => (
                  <div key={option.value} className="flex items-center gap-2">
                    <FormControlLabel
                      checked={option.value === selectedMappingType}
                      // @ts-ignore
                      onChange={() => !option.disabled && handleMetricTypeChange(option.value)}
                      value={option.value}
                      label={option.label}
                      control={<Radio />}
                      data-cy={option.value}
                      disabled={option.disabled}
                    />
                  </div>
                ))}
              </RadioGroup>
            </div>
            <div>{renderInputsBasedOnMappingType(selectedMappingType)}</div>
          </div>
        </div>
        <SidePanelActions className="!fixed bottom-0 z-[3400] !w-[calc(100%-218px)] border-t border-neutral-100">
          <Button id="previous" variant="contained" className="w-fit">
            Save progress and apply
          </Button>
          <div className="flex justify-end gap-3">
            <Button
              id="previous"
              variant="contained"
              onClick={() => {
                const previousFunnelStep = getNextOrPreviousFunnelStep('previous');
                setSelectedFunnelStep({
                  title: previousFunnelStep.title,
                  funnel_step_id: previousFunnelStep.funnelStepId,
                  type: previousFunnelStep.type,
                });
              }}
            >
              Previous step
            </Button>
            <ButtonAsync
              id="next"
              variant="contained"
              color="primary"
              // disabled={
              //   // assignedMetrics.some((metric) => metric.mappingType !== 'ignore_mapping' && !metric.metricId) ||
              //   !getNextOrPreviousFunnelStep('next')
              // }
              // loading={upsertPortfolioContentConfigLoading}
              onClick={() => {
                const nextFunnelStep = getNextOrPreviousFunnelStep('next');
                if (!nextFunnelStep) {
                  toggleApplyDialog();
                } else {
                  setSelectedFunnelStep({
                    title: nextFunnelStep.title,
                    funnel_step_id: nextFunnelStep.funnelStepId,
                    type: nextFunnelStep.type,
                  });
                }
              }}
            >
              {getNextOrPreviousFunnelStep('next') ? 'Next funnel step' : 'Review and apply'}
            </ButtonAsync>
          </div>
        </SidePanelActions>
        <AlertDialog open={isOpenApplyDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Review and apply metrics</AlertDialogTitle>
              <AlertDialogDescription>
                <span className="mt-1 text-sm font-normal leading-5 text-neutral-400">
                  Here’s a summary of your assigned metrics per funnel step.
                </span>
              </AlertDialogDescription>
            </AlertDialogHeader>

            <div className="rounded-lg border border-neutral-100 bg-seasalt">
              <div className="grid grid-cols-2 p-6 py-2">
                <LabelLight className="!mb-0">Funnel step</LabelLight>
                <LabelLight className="!mb-0">Assigned metric</LabelLight>
              </div>
              <div className="h-[1px] w-full bg-neutral-100" />
              <div className="flex">
                <div className="grid grid-cols-1 p-6 py-2">
                  {funnelSteps.map((funnelStep) => (
                    <div key={funnelStep?.funnelStepId} className="text-md py-3 pr-8 font-normal text-neutral-900">
                      {capitalize(funnelStep.title)}
                    </div>
                  ))}
                </div>
                <div className="h-auto w-[1px] bg-neutral-100" />
                <div className="grid grid-cols-1 p-7 py-2">
                  {funnelSteps.map((funnelStep) => {
                    const { assignedMetricName, mappingType, mappingTypeLabel } = getAssignedMetricBasedOnMappingType(
                      funnelStep.funnelStepId,
                      assignedMetrics,
                      getSelectedMeasurementForFunnelStepId,
                      translations,
                    );

                    return (
                      <div key={funnelStep?.funnelStepId} className="flex flex-col">
                        <div
                          className={cn(
                            'text-md py-3 pr-8 font-normal',
                            mappingType === 'ignore_mapping' ? 'text-neutral-400' : 'text-neutral-900',
                          )}
                        >
                          {assignedMetricName}
                        </div>
                        <LabelLight className="!text-[11px] !text-neutral-300">
                          Mapping type: {mappingTypeLabel}
                        </LabelLight>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <AlertDialogFooter>
              <AlertDialogAction>
                <ButtonAsync onClick={closeApplyDialog} variant="contained" color="secondary" size="small">
                  Go back
                </ButtonAsync>
              </AlertDialogAction>

              <AlertDialogAction>
                <ButtonAsync onClick={handleSubmit} variant="contained" color="primary" size="small">
                  Apply metrics
                </ButtonAsync>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </SidePanel>
    </>
  );
};
