import Typography from '../../../components/Typography';
import { nexyColors } from '../../../theme';
import React from 'react';
import Button from '../../../components/Button';
import { LabelLight } from '../../../components/InputLabel/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components-ui/Select';
import { HoverableTooltip, HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components-ui/HoverCard';

const contents = [
  { contentId: 1, title: 'Content1' },
  { contentId: 2, title: 'Content2' },
  { contentId: 3, title: 'Content3' },
  { contentId: 4, title: 'Content4' },
  { contentId: 5, title: 'Content5' },
];

const contentRuleMetrics = [
  { id: 'metric1', name: 'Metric 1' },
  { id: 'metric2', name: 'Metric 2' },
];

const impactGroupRules = [
  { id: 'rule1', name: 'Rule 1' },
  { id: 'rule2', name: 'Rule 2' },
];

export const UnappliedRules = () => {
  return (
    <div>
      <div className="mb-6 flex justify-between">
        <div>
          <Typography style={{ color: nexyColors.neutral900 }} variant="h3">
            Unapplied rules
          </Typography>
          <Typography style={{ color: nexyColors.neutral400, marginTop: 8 }} variant="paragraph">
            Assign content rule metrics, impact group rules, and calibration rules to portfolio contents.
          </Typography>
        </div>
        <Button
          className="h-fit"
          onClick={() => console.log('Apply selected rules')}
          color="primary"
          variant="contained"
        >
          Apply selected rules
        </Button>
      </div>

      {/* Table Container */}
      <div className="rounded-lg border border-neutral-100">
        {/* Table Header */}
        <div className="grid grid-cols-[1fr_0.5fr_0.5fr] px-6 py-3 font-medium text-neutral-600">
          <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Content name</LabelLight>
          <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">Content rule metrics</LabelLight>
          <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">Impact group rules</LabelLight>
        </div>

        {/* Table Rows */}
        {contents.map((content) => (
          <div
            key={content.contentId}
            className="grid grid-cols-[1fr_0.5fr_0.5fr] border-t border-neutral-100 px-6 py-4"
          >
            {/* Content Name */}
            <div className="w-fit text-neutral-900">
              <HoverCard>
                <HoverCardTrigger>
                  <HoverableTooltip>{content.title}</HoverableTooltip>
                </HoverCardTrigger>
                <HoverCardContent side="right">
                  <div className="text-mdlg text-neutral-800">{content.title}</div>
                  <div className="text-sm text-neutral-400">this is a content</div>
                </HoverCardContent>
              </HoverCard>
            </div>

            {/* Shadcn Select for Content Rule Metrics */}
            <div>
              <Select onValueChange={(value) => console.log(`Selected metric: ${value}`)}>
                <SelectTrigger className="w-56 border-none bg-transparent p-2">
                  <HoverCard>
                    <HoverCardTrigger>
                      <HoverableTooltip>
                        <SelectValue
                          className="placeholder:text-neutral-100"
                          placeholder="Select content rule metrics"
                        />
                      </HoverableTooltip>
                    </HoverCardTrigger>
                    <HoverCardContent className="flex flex-col items-start justify-start">
                      <div className="text-mdlg text-neutral-800">Metric</div>
                      <div className="text-sm text-neutral-400">this is a metric</div>
                    </HoverCardContent>
                  </HoverCard>
                </SelectTrigger>
                <SelectContent>
                  {contentRuleMetrics.map((metric) => (
                    <SelectItem key={metric.id} value={metric.id}>
                      {metric.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Shadcn Select for Impact Group Rules */}
            <div>
              <Select onValueChange={(value) => console.log(`Selected rule: ${value}`)}>
                <SelectTrigger className="w-56 border-none bg-transparent p-2">
                  <HoverCard>
                    <HoverCardTrigger>
                      <HoverableTooltip>
                        <SelectValue placeholder="Select impact group rule" />
                      </HoverableTooltip>
                    </HoverCardTrigger>
                    <HoverCardContent className="flex flex-col items-start justify-start">
                      <div className="text-mdlg text-neutral-800">Impact group rule</div>
                      <div className="text-sm text-neutral-400">this is a rule</div>
                    </HoverCardContent>
                  </HoverCard>
                </SelectTrigger>
                <SelectContent>
                  {impactGroupRules.map((rule) => (
                    <SelectItem key={rule.id} value={rule.id}>
                      {rule.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
