import React, { Dispatch, JSX, SetStateAction, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../../../components-ui/Select';
import { LabelLight } from '../../../../../../components/InputLabel/styles';
import { Input } from '../../../../../../components-ui/Input';
import { Button } from '../../../../../../components-ui/Button';
import { CirclePlus, Trash2 } from 'lucide-react';
import RadioGroup from '../../../../../../components/RadioGroup';
import FormControlLabel from '../../../../../../components/FormControlLabel';
import Radio from '../../../../../../components/Radio';
import { useFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/queryFunnelStepMappingPreset';
import { useRouteMatch } from 'react-router';
import {
  NexoyaFunnelStepMapping,
  NexoyaFunnelStepMappingPreset,
  NexoyaFunnelStepMappingPresetMappingInput,
  NexoyaFunnelStepUtmMappingParams,
} from '../../../../../../types';
import { useSaveFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/mutationSaveFunnelStepMappingPreset';
import { useTeam } from '../../../../../../context/TeamProvider';
import { useFunnelStepUtmMapping } from '../../../../../../graphql/portfolio/queryFunnelStepUtmMapping';
import { MappingType } from '../../ContentMetricAssignment';
import { useUpdateFunnelStepMappingPreset } from '../../../../../../graphql/portfolioRules/mutationUpdateFunnelStepMappingPreset';

const METRIC_TYPES = [
  { value: 'metric', label: 'Metric' },
  { value: 'event_metric', label: 'Event and Metric' },
];

type AssignedMappingExtended = NexoyaFunnelStepMapping & {
  mappingType?: MappingType;
  presetName?: string;
  eventName?: string;
  selectedMetricType?: 'metric' | 'event_metric';
};

export const UTMTracking = ({
  assignedMetrics,
  setAssignedMetrics,
  renderAssignedMetricCombobox,
  selectedMetricId,
  selectedConversionId,
  funnelStepId,
}: {
  assignedMetrics: AssignedMappingExtended[];
  setAssignedMetrics: Dispatch<SetStateAction<AssignedMappingExtended[]>>;
  renderAssignedMetricCombobox: (triggerClassName?: string) => JSX.Element;
  selectedMetricId: number;
  selectedConversionId: string;
  funnelStepId: number;
}) => {
  const { teamId } = useTeam();
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const { data: funnelStepsUtmMappingData } = useFunnelStepUtmMapping();
  const utmTrackingOptions: NexoyaFunnelStepUtmMappingParams[] =
    funnelStepsUtmMappingData?.funnelStepUtmMappingParams?.filter((option) => option.type !== 'event_name') || [];

  const [saveFunnelStepMappingPreset, { loading: loadingSavePreset }] = useSaveFunnelStepMappingPreset({ portfolioId });
  const [updateFunnelStepMappingPreset] = useUpdateFunnelStepMappingPreset({
    portfolioId,
  });

  const { data: funnelStepPresetsData } = useFunnelStepMappingPreset();

  const mappingPresets: NexoyaFunnelStepMappingPreset[] = funnelStepPresetsData?.listFunnelStepMappingPresets || [];

  // Ensure we have an entry in assignedMetrics for this funnelStepId.
  // If not, create one with defaults.
  useEffect(() => {
    setAssignedMetrics((prev) => {
      const index = prev.findIndex((m) => m.funnelStepId === funnelStepId);
      if (index === -1) {
        // Create a new entry with defaults
        return [
          ...prev,
          {
            funnelStepId,
            metricId: selectedMetricId,
            conversionExternalIdentifierHash: selectedConversionId,
            utmParams: [],
            mappingType: 'utm_tracking',
            presetName: '',
            selectedMetricType: undefined,
          },
        ];
      }
      return prev;
    });
  }, [funnelStepId, selectedMetricId, selectedConversionId, setAssignedMetrics]);

  useEffect(() => {
    if (utmTrackingOptions && !utmParams.length) {
      handleAddParam();
    }
  }, [funnelStepId]);

  // Helper function to update the assignedMetrics for this funnelStepId
  const updateAssignedMapping = (partial: Partial<AssignedMappingExtended>) => {
    setAssignedMetrics((prev) => {
      const index = prev.findIndex((m) => m.funnelStepId === funnelStepId);
      if (index === -1) return prev; // should never happen since we ensure an entry above
      const newMetrics = [...prev];
      newMetrics[index] = { ...newMetrics[index], ...partial };
      return newMetrics;
    });
  };

  // Derived values from assignedMetrics
  const currentMappingIndex = assignedMetrics.findIndex((m) => m.funnelStepId === funnelStepId);
  const currentMapping = currentMappingIndex !== -1 ? assignedMetrics[currentMappingIndex] : undefined;

  const utmParams = currentMapping?.utmParams || [];
  const presetName = currentMapping?.presetName || '';
  const selectedMetricType = currentMapping?.selectedMetricType;

  const handleAddParam = () => {
    if (utmParams.length < 5) {
      const newParams = [...utmParams, { type: '', value: '' }];
      updateAssignedMapping({ utmParams: newParams });
    }
  };

  const handleDeleteParam = (paramIndex: number) => {
    if (paramIndex === 0) return;
    const newParams = utmParams.filter((_, i) => i !== paramIndex);
    updateAssignedMapping({ utmParams: newParams });
  };

  const handleUpdateParam = (paramIndex: number, key: 'type' | 'value', value: string) => {
    const newParams = utmParams.map((p, i) => (i === paramIndex ? { ...p, [key]: value } : p));
    updateAssignedMapping({ utmParams: newParams });
  };

  const handleSelectPreset = (id: string) => {
    const selectedPreset = mappingPresets.find((preset) => preset.funnelStepMappingPresetId.toString() === id);
    if (selectedPreset) {
      // Extract UTM parameters from the preset
      const newUtmParams = (selectedPreset.mapping?.utmParams || []).map((p) => ({ type: p.type, value: p.value }));

      // Extract 'event_name' if present in UTM parameters
      const eventNameParam = newUtmParams.find((p) => p.type === 'event_name');
      const eventName = eventNameParam ? eventNameParam.value : undefined;

      // Derive selectedMetricType from preset (if conversionExternalIdentifierHash is set, then event_metric, else metric)
      const newMetricType = eventName
        ? 'event_metric'
        : selectedPreset.mapping?.conversionExternalIdentifierHash
          ? 'event_metric'
          : 'metric';

      // Filter out 'event_name' from the UTM parameters (optional, if you don't want it to appear in the UTM list)
      const filteredUtmParams = newUtmParams.filter((p) => p.type !== 'event_name');

      updateAssignedMapping({
        metricId: selectedPreset.mapping?.metricId,
        presetName: selectedPreset.name || '',
        selectedMetricType: newMetricType,
        utmParams: filteredUtmParams,
        eventName, // Set extracted event name
      });
    }
  };

  const handleSaveConfiguration = () => {
    if (!presetName.trim() || !selectedMetricId) return;

    // Check if preset already exists
    const existingPreset = mappingPresets.find((p) => p.name.toLowerCase() === presetName.trim().toLowerCase());

    // Build mapping from current assignedMetrics
    const mappedParams = utmParams.filter((p) => p.type && p.type.trim() && p.value.trim());

    if (currentMapping?.eventName) {
      mappedParams.push({ type: 'event_name', value: currentMapping?.eventName });
    }

    const mapping: NexoyaFunnelStepMappingPresetMappingInput = {
      conversionExternalIdentifierHash: selectedConversionId,
      metricId: selectedMetricId,
      utmParams: mappedParams.map((p) => ({
        type: p.type,
        value: p.value,
      })),
    };

    updateAssignedMapping({ presetName }); // store presetName in assignedMetrics

    if (existingPreset) {
      updateFunnelStepMappingPreset({
        variables: {
          teamId,
          name: presetName,
          mapping,
          funnelStepMappingPresetId: existingPreset.funnelStepMappingPresetId,
        },
      });
    } else {
      saveFunnelStepMappingPreset({
        variables: {
          teamId,
          name: presetName,
          mapping,
        },
      });
    }
  };

  return (
    <div className="flex max-w-[526px] flex-col gap-6">
      <div className="flex flex-col gap-1">
        <div className="mt-3 text-lg text-neutral-700">GA4 Tracking</div>
        <div className="mt-0.5 max-w-lg text-sm font-light text-neutral-400">
          Use an existing GA4 Tracking configuration or create a new configuration for this funnel step.
        </div>
        <div className="mt-4">
          <LabelLight>Saved configurations</LabelLight>
          <Select disabled={!mappingPresets?.length} onValueChange={handleSelectPreset}>
            <SelectTrigger className="max-w-xl border-neutral-100 bg-white p-2 shadow-sm">
              {loadingSavePreset ? (
                'Loading...'
              ) : (
                <SelectValue placeholder={!mappingPresets?.length ? 'No configurations saved' : 'Load configuration'} />
              )}
            </SelectTrigger>
            <SelectContent>
              {mappingPresets.map((config) => (
                <SelectItem key={config.funnelStepMappingPresetId} value={config.funnelStepMappingPresetId.toString()}>
                  <span>{config.name}</span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      {/* UTM Parameters */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="text-md mt-3 font-semibold tracking-normal text-neutral-700">
            Configuration step 1: UTM Parameters
          </div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            Select up to 5 different UTM parameters for this funnel step.
            <br />
            This step is optional.
          </div>
        </div>
        <div>
          {utmParams.map((param, index) => (
            <div key={index} className="mb-4 flex w-full flex-row items-end justify-between gap-3">
              <div className="w-44">
                <LabelLight style={{ fontSize: 11 }}>Parameter type {index + 1}</LabelLight>
                <Select
                  value={param.type || undefined}
                  onValueChange={(value) => handleUpdateParam(index, 'type', value)}
                >
                  <SelectTrigger className="max-w-xl whitespace-pre border-neutral-100 bg-white p-2 shadow-sm">
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                  <SelectContent>
                    {utmTrackingOptions.map((option) => (
                      <SelectItem key={option.type} value={option.type}>
                        <span>{option.name}</span>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="w-full">
                <LabelLight style={{ fontSize: 11 }}>Parameter value</LabelLight>
                <Input
                  className="shadow-sm"
                  placeholder="Enter value"
                  value={param.value}
                  onChange={(e) => handleUpdateParam(index, 'value', e.target.value)}
                />
              </div>
              <div>
                <Button size="icon" variant="ghost" disabled={index === 0} onClick={() => handleDeleteParam(index)}>
                  <Trash2 className="h-5 w-5 text-neutral-300" />
                </Button>
              </div>
            </div>
          ))}
          <div className="flex items-center gap-4">
            <div className="h-[1px] w-full bg-neutral-100"></div>
            <Button onClick={handleAddParam} size="icon" variant="ghost">
              <CirclePlus className="h-5 w-5 text-neutral-300" />
            </Button>
          </div>
        </div>
      </div>
      {/* Metric Type */}
      <div className="flex max-w-[526px] flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="text-md font-semibold tracking-normal text-neutral-700">
            Configuration step 2: Metric Type
          </div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            Select one metric type for this funnel step.
            <br />
            This step is mandatory.
          </div>
        </div>
        <div className="flex items-end gap-4">
          <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
            {METRIC_TYPES.map((option) => (
              <div key={option.value} className="flex items-center gap-2">
                <FormControlLabel
                  checked={option.value === selectedMetricType}
                  onChange={() =>
                    updateAssignedMapping({ selectedMetricType: option.value as 'metric' | 'event_metric' })
                  }
                  value={option.value}
                  label={option.label}
                  control={<Radio />}
                  data-cy={option.value}
                />
              </div>
            ))}
          </RadioGroup>
          {selectedMetricType === 'metric' ? (
            <div className="w-full">
              <LabelLight>Metric</LabelLight>
              {renderAssignedMetricCombobox('w-full')}
            </div>
          ) : null}
          {selectedMetricType === 'event_metric' ? (
            <div className="flex w-full gap-2">
              <div>
                <LabelLight>Event name</LabelLight>
                <Input
                  className="shadow-sm"
                  placeholder="Enter event name"
                  value={currentMapping?.eventName}
                  onChange={(e) => updateAssignedMapping({ eventName: e.target.value })}
                />
              </div>
              <div>
                <LabelLight>Metric</LabelLight>
                {renderAssignedMetricCombobox('w-44')}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Save Configuration */}
      <div className="mb-40 flex max-w-[526px] flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="text-md font-semibold tracking-normal text-neutral-700">Save GA4 Tracking configuration</div>
          <div className="mt-0.5 text-sm font-light text-neutral-400">
            You can save your configuration above and give it a name for future use.
          </div>
        </div>
        <div className="flex items-end gap-4">
          <div className="w-full">
            <Input
              className="shadow-sm"
              placeholder="Configuration name"
              value={presetName}
              onChange={(e) => updateAssignedMapping({ presetName: e.target.value })}
            />
          </div>
          <Button disabled={!presetName || !selectedMetricId} variant="secondary" onClick={handleSaveConfiguration}>
            Save configuration
          </Button>
        </div>
      </div>
    </div>
  );
};
