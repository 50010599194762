import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../components-ui/AlertDialog';
import ButtonAsync from '../../../components/ButtonAsync';
import React from 'react';
import SvgCheckCircle from '../../../components/icons/CheckCircle';
import Link2 from '../../../components/icons/Link2';
import { LabelLight } from '../../../components/InputLabel/styles';
import { HoverableTooltip, HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components-ui/HoverCard';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components-ui/Select';

const contentsx = [
  { contentId: 1, title: 'Content1' },
  { contentId: 2, title: 'Content2' },
  { contentId: 3, title: 'Content3' },
  { contentId: 4, title: 'Content4' },
  { contentId: 5, title: 'Content5' },
];

const contentRuleMetrics = [
  { id: 'metric1', name: 'Metric 1' },
  { id: 'metric2', name: 'Metric 2' },
];

const impactGroupRules = [
  { id: 'rule1', name: 'Rule 1' },
  { id: 'rule2', name: 'Rule 2' },
];

export const ApplyContentRules = ({ isOpen, onCancel, onConfirm, disabled, contents }) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="min-w-[920px]">
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle>Apply rules to contents</AlertDialogTitle>
          <AlertDialogDescription>
            <div className="mb-3 flex items-center gap-2 rounded-md border border-neutral-100 bg-neutral-50 p-3">
              <SvgCheckCircle className="h-4 w-4 text-green-400" />
              <span className="text-sm leading-5 text-neutral-800">
                {contents.length} matching contents have been added to your portfolio.
              </span>
            </div>
            <div className="flex gap-2 rounded-md border border-neutral-100 bg-neutral-50 p-3">
              <Link2 className="h-5 w-5 text-green-400" />
              <div className="flex flex-col">
                <span className="text-md leading-5 text-neutral-800">Assign rules to newly added contents</span>
                <span className="text-xs font-normal leading-5 text-neutral-700">
                  Assign a content rule metric, impact group rule and calibration rule to your newly added contents
                  below.
                </span>
              </div>
            </div>
          </AlertDialogDescription>
          <div className="rounded-lg border border-neutral-100 bg-neutral-50">
            {/* Table Header */}
            <div className="grid grid-cols-3 px-6 py-3 font-medium text-neutral-600">
              <LabelLight className="!mb-0 px-0 font-semibold !text-neutral-500">Content name</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">Content rule metrics</LabelLight>
              <LabelLight className="!mb-0 px-2 font-semibold !text-neutral-500">Impact group rule</LabelLight>
            </div>

            {/* Table Rows */}
            {contentsx.map((content) => (
              <div key={content.contentId} className="grid grid-cols-3 border-t border-neutral-100 px-6 py-4">
                {/* Content Name */}
                <div className="w-fit text-neutral-900">
                  <HoverCard>
                    <HoverCardTrigger>
                      <HoverableTooltip>{content.title}</HoverableTooltip>
                    </HoverCardTrigger>
                    <HoverCardContent side="right">
                      <div className="text-mdlg text-neutral-800">{content.title}</div>
                      <div className="text-sm text-neutral-400">this is a content</div>
                    </HoverCardContent>
                  </HoverCard>
                </div>

                {/* Shadcn Select for Content Rule Metrics */}
                <div>
                  <Select onValueChange={(value) => console.log(`Selected metric: ${value}`)}>
                    <SelectTrigger className="w-56 border-none bg-transparent p-2">
                      <HoverCard>
                        <HoverCardTrigger>
                          <HoverableTooltip>
                            <SelectValue
                              className="placeholder:text-neutral-100"
                              placeholder="Select content rule metrics"
                            />
                          </HoverableTooltip>
                        </HoverCardTrigger>
                        <HoverCardContent className="flex flex-col items-start justify-start">
                          <div className="text-mdlg text-neutral-800">Metric</div>
                          <div className="text-sm text-neutral-400">this is a metric</div>
                        </HoverCardContent>
                      </HoverCard>
                    </SelectTrigger>
                    <SelectContent>
                      {contentRuleMetrics.map((metric) => (
                        <SelectItem key={metric.id} value={metric.id}>
                          {metric.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Shadcn Select for Impact Group Rules */}
                <div>
                  <Select onValueChange={(value) => console.log(`Selected rule: ${value}`)}>
                    <SelectTrigger className="w-56 border-none bg-transparent p-2">
                      <HoverCard>
                        <HoverCardTrigger>
                          <HoverableTooltip>
                            <SelectValue placeholder="Select impact group rule" />
                          </HoverableTooltip>
                        </HoverCardTrigger>
                        <HoverCardContent className="flex flex-col items-start justify-start">
                          <div className="text-mdlg text-neutral-800">Impact group rule</div>
                          <div className="text-sm text-neutral-400">this is a rule</div>
                        </HoverCardContent>
                      </HoverCard>
                    </SelectTrigger>
                    <SelectContent>
                      {impactGroupRules.map((rule) => (
                        <SelectItem key={rule.id} value={rule.id}>
                          {rule.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            ))}
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync disabled={disabled} onClick={onCancel} variant="contained" color="secondary" size="small">
              Select rules later
            </ButtonAsync>
          </AlertDialogAction>

          <AlertDialogAction>
            <ButtonAsync disabled={disabled} onClick={onConfirm} variant="contained" color="primary" size="small">
              Apply selected rules
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
