import React, { useEffect, useState } from 'react';

import Button from '../../../../components/Button';
import ButtonAsync from '../../../../components/ButtonAsync';
import SidePanel, { SidePanelActions } from '../../../../components/SidePanel';
import ContentSelectionV2 from '../../../../components/ContentSelection/ContentSelectionV2';
import { useTeam } from 'context/TeamProvider';
import {
  NexoyaContentFilter,
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaContentRule,
} from '../../../../types';
import { DataTableFilterOption } from './ContentRule/types';
import { useDialogState } from '../../../../components/Dialog';
import { SaveFilterDialog } from '../../../../components/ContentSelection/components/SaveFilterDialog';
import { getFilterValueInputBasedOnType, PortfolioRuleType } from './ContentRule/utils';
import { NexoyaImpactGroupRule } from '../../../../graphql/portfolioRules/queryImpactGroupRules';
import { usePortfolio } from '../../../../context/PortfolioProvider';

export type CommonRuleConfig = {
  type: PortfolioRuleType;
  createMutation: (variables: any) => Promise<any>;
  updateMutation: (variables: any) => Promise<any>;
  labels: {
    name: string;
    saveButton: string;
  };
};

type Props = {
  toggleSidePanel: () => void;
  isOpen: boolean;
  portfolioId: number;
  config: CommonRuleConfig;
  rule?: NexoyaContentRule | NexoyaImpactGroupRule;
  loading: {
    create: boolean;
    update: boolean;
  };
};

function CreateOrUpdatePortfolioRule({ toggleSidePanel, isOpen, portfolioId, rule, config, loading }: Props) {
  const [selectedContentIds, setSelectedContentIds] = useState<number[]>(
    rule ? rule?.contents?.map((c) => c.contentId) : [],
  );
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(rule ? rule.providerId : null);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(rule ? rule.adAccountId : null);

  // const [selectedProviderIds, setSelectedProviderIds] = React.useState<number[]>(
  //   config.type === 'impact-group-rule'
  //     ? (rule?.providerIds || [])
  //     : rule?.providerId
  //       ? [rule.providerId]
  //       : []
  // )
  //
  // const [selectedAccountIds, setSelectedAccountIds] = React.useState<number[]>(
  //   config.type === 'impact-group-rule'
  //     ? (rule?.adAccountIds || [])
  //     : rule?.adAccountId
  //       ? [rule.adAccountId]
  //       : []
  // )
  // @ts-ignore
  const [selectedImpactGroupId, setSelectedImpactGroupId] = useState<number | null>(rule ? rule.impactGroupId : null);

  const [selectedOptions, setSelectedOptions] = useState<DataTableFilterOption[]>([]);
  const [pendingFilters, setPendingFilters] = useState<NexoyaContentFilter[]>([]);

  const { teamId } = useTeam();

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const impactGroups = portfolioMeta?.impactGroups || [];

  const {
    isOpen: isOpenApplyDialog,
    toggleDialog: toggleApplyDialog,
    closeDialog: closeApplyDialog,
  } = useDialogState();

  useEffect(() => {
    if (rule) {
      setSelectedOptions([]);
      setSelectedProviderId(rule.providerId);
      setSelectedAccountId(rule.adAccountId);
      setPendingFilters(rule.filters); // Store filters in a temporary state
    }
  }, []);

  const handleSubmit = (name: string) => {
    const isImpactGroupRule = config.type === 'impact-group-rule';

    const variables = {
      portfolioId,
      teamId,
      name,
      providerIds: selectedProviderId ? [selectedProviderId] : [],
      adAccountIds: selectedAccountId ? [selectedAccountId] : [],
      filters: !isImpactGroupRule
        ? selectedOptions.map((option) => ({
            fieldName: option.value as NexoyaContentFilterFieldName,
            operator: option.filterOperator as NexoyaContentFilterOperator,
            value: getFilterValueInputBasedOnType(option.type, option.filterValues),
          }))
        : undefined,
      impactGroupId: isImpactGroupRule ? selectedImpactGroupId : undefined,
    };

    if (rule) {
      const ruleId = isImpactGroupRule
        ? // @ts-ignore
          { impactGroupRuleId: rule.impactGroupRuleId }
        : { contentRuleId: rule.contentRuleId };
      // Update the rule using config.updateMutation
      config
        .updateMutation({
          variables: {
            ...variables,
            ...ruleId,
          },
        })
        .finally(() => closeSidepanel());
    } else {
      // Create a new rule using config.createMutation
      config
        .createMutation({
          variables,
        })
        .finally(() => closeSidepanel());
    }
  };

  const closeSidepanel = () => {
    toggleSidePanel();
    setSelectedProviderId(null);
    setSelectedAccountId(null);
    setSelectedImpactGroupId(null);
    setSelectedContentIds([]);
    setSelectedOptions([]);
  };

  return (
    <>
      <SidePanel
        isOpen={isOpen}
        onClose={closeSidepanel}
        paperProps={{ style: { width: 'calc(100% - 218px)', paddingBottom: '78px' } }}
      >
        <div className="border border-b-[#eaeaea] px-6 py-5">
          <h3 className="text-xl font-medium text-neutral-900">
            {rule ? `Edit ${config.labels.name}` : `Create ${config.labels.name}`}
          </h3>
        </div>
        <div className="p-6">
          <ContentSelectionV2
            setSelectedProviderId={setSelectedProviderId}
            selectedProviderId={selectedProviderId}
            setSelectedContentIds={setSelectedContentIds}
            selectedContentIds={selectedContentIds}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            pendingFilters={pendingFilters}
            setPendingFilters={setPendingFilters}
          />
        </div>
        <SidePanelActions className="!fixed bottom-0 z-[3400] !w-[calc(100%-218px)] border-t border-neutral-100">
          <Button
            disabled={loading.create || loading.update}
            id="previous"
            variant="contained"
            onClick={closeSidepanel}
          >
            Cancel
          </Button>
          <ButtonAsync
            id="next"
            variant="contained"
            color="primary"
            disabled={!selectedContentIds?.length || loading.create || loading.update}
            loading={loading.create || loading.update}
            onClick={toggleApplyDialog}
            style={{ marginLeft: 'auto' }}
          >
            {rule ? 'Save' : 'Finish'}
          </ButtonAsync>
        </SidePanelActions>
        <SaveFilterDialog
          config={config}
          impactGroups={impactGroups}
          initialName={rule?.name}
          selectedImpactGroupId={selectedImpactGroupId}
          setSelectedImpactGroupId={setSelectedImpactGroupId}
          closeDialog={closeApplyDialog}
          loading={loading.create || loading.update}
          open={isOpenApplyDialog}
          handleSave={(name) => handleSubmit(name)}
          contentsToAddLength={selectedContentIds?.length}
        />
      </SidePanel>
    </>
  );
}

export default CreateOrUpdatePortfolioRule;
