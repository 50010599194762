import { Calendar, CircleDashed, Fingerprint, Layers, Target, Type } from 'lucide-react';
import {
  NexoyaContentFilter,
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaFieldAllowedValues,
} from '../../../../../types';
import React from 'react';
import dayjs from 'dayjs';
import { toNumber } from 'lodash';
import { GLOBAL_DATE_FORMAT } from '../../../../../utils/dates';
import { FilterType, Operator } from './types';
import translate from '../../../../../utils/translate';

export type PortfolioRuleType = 'content-rule' | 'impact-group-rule' | 'calibration-rule';
export const CONTENT_TYPE_CAMPAIGN_DEFAULT_VALUE = 'campaign';
const DEFAULT_DAYJS_YEAR_WHEN_NO_YEAR_IS_PRESENT_IN_DATE = 2001;

export const humanizeFieldName = (fieldName: string) => {
  const fieldMapping: Record<string, string> = {
    providerId: 'Provider id',
    collectionId: 'Collection id',
    teamId: 'Team ID',
    title: 'Title',
    collectionType: 'Collection Type',
    parentCollectionId: 'Parent Collection id',
    parentTitle: 'Parent Title',
    startDate: 'Start Date',
    endDate: 'End Date',
    latestMeasurementDataDate: 'Last Measurement date',
    bidStrategy: 'Bid Strategy',
    hasSpendLast7Days: 'Has spend in the last 7 days',
  };

  return fieldMapping[fieldName] || fieldName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

export const OPERATORS_MAP: Record<string, Operator> = {
  eq: { label: 'Is', value: NexoyaContentFilterOperator.Eq, humanReadable: 'Is' },
  ne: { label: 'Is not', value: NexoyaContentFilterOperator.Ne, humanReadable: 'Not' },
  gte: { label: 'Is on or after', value: NexoyaContentFilterOperator.Gte, humanReadable: 'On or after' },
  lte: { label: 'Is on or before', value: NexoyaContentFilterOperator.Lte, humanReadable: 'On or before' },
  contains: { label: 'Contains', value: NexoyaContentFilterOperator.Contains, humanReadable: 'Contains' },
  notContains: { label: 'Does not contain', value: NexoyaContentFilterOperator.NotContains, humanReadable: 'Not' },
  // startsWith: { label: 'Starts with', value: 'startsWith', humanReadable: 'Starts with' },
  // endsWith: { label: 'Ends with', value: 'endsWith', humanReadable: 'Ends with' },
  // isNull: { label: 'Is empty', value: 'isNull', humanReadable: 'Empty' },
  // isNotNull: { label: 'Is not empty', value: 'isNotNull', humanReadable: 'Not empty' },
};

// Utility to map incoming operations to UI operators
export const mapOperationsToUI = (operators: string[]) => operators.map((op) => OPERATORS_MAP[op]).filter(Boolean); // Filter out undefined mappings

export const getIconForField = (fieldName: NexoyaContentFilterFieldName) => {
  switch (fieldName) {
    case 'contentId':
      return <Fingerprint className="h-3.5 w-3.5" />;
    case 'title':
      return <Type className="h-3.5 w-3.5" />;
    case 'contentType':
      return <Layers className="h-3.5 w-3.5" />;
    case 'parentContentId':
      return <Layers className="h-3.5 w-3.5" />;
    case 'parentTitle':
      return <Type className="h-3.5 w-3.5" />;
    case 'startDate':
      return <Calendar className="h-3.5 w-3.5" />;
    case 'endDate':
      return <Calendar className="h-3.5 w-3.5" />;
    case 'latestMeasurementDataDate':
      return <Calendar className="h-3.5 w-3.5" />;
    case 'bidStrategy':
      return <Target className="h-3.5 w-3.5" />;
    case 'status':
      return <CircleDashed className="h-3.5 w-3.5" />;
    case 'hasSpendLast7Days':
      return <CircleDashed className="h-3.5 w-3.5" />;
    default:
      return null;
  }
};

export const getTypeForField = (allowedFields: NexoyaFieldAllowedValues) => {
  if (allowedFields.enumOptionsNumber?.length) {
    return 'numberArr';
  } else if (allowedFields.enumOptionsString?.length) {
    return 'stringArr';
  }

  return allowedFields.fieldType;
};

export const getFilterValueInputBasedOnType = (type: FilterType, filterValues: string[]) => {
  const value = {
    date: null,
    number: null,
    numberArr: null,
    string: null,
    stringArr: null,
    boolean: null,
  };

  switch (type) {
    case 'date':
      const parsedDate = dayjs(filterValues?.[0]);

      // Check if the parsed date doesn't have a year and set it to the current year
      if (!parsedDate.isValid() || parsedDate.year() === DEFAULT_DAYJS_YEAR_WHEN_NO_YEAR_IS_PRESENT_IN_DATE) {
        value.date = parsedDate.year(dayjs().year()).format(GLOBAL_DATE_FORMAT);
      } else {
        value.date = parsedDate.format(GLOBAL_DATE_FORMAT);
      }
      break;
    case 'string':
      value.string = filterValues?.[0];
      break;
    case 'boolean':
      value.boolean = filterValues?.[0] === 'true';
      break;
    case 'number':
      value.number = toNumber(filterValues?.[0]);
      break;
    case 'numberArr':
      value.numberArr = filterValues?.map((val) => toNumber(val));
      break;
    case 'stringArr':
      value.stringArr = filterValues;
      break;
    default:
      break;
  }
  return value;
};

export const getFilterValuesFromFilter = (filter: NexoyaContentFilter) => {
  if (filter.value?.stringArr) return filter.value.stringArr;
  if (filter.value?.string) return [filter.value.string];
  if (filter.value?.numberArr) return filter.value.numberArr;
  if (filter.value?.number) return [String(filter.value.number)];
  if (filter.value?.date) return [dayjs(filter.value.date).format('DD MMM, YYYY')];
  return [];
};

export const displayFilterValue = (filter: NexoyaContentFilter) => {
  const filterValues = getFilterValuesFromFilter(filter);

  if (filterValues.length > 1) {
    return `${filterValues.length} selected`;
  }

  return filterValues[0];
};

export const getAssignedMetricBasedOnMappingType = (
  funnelStepId: number,
  assignedMetrics: any,
  getSelectedMeasurementForFunnelStepId: any,
  translations,
) => {
  const assignedMetric = assignedMetrics.find((metric) => metric.funnelStepId === funnelStepId);
  const assignedMetricId = getSelectedMeasurementForFunnelStepId(funnelStepId);
  const assignedMetricName = translate(translations, assignedMetricId?.name);

  switch (assignedMetric?.mappingType) {
    case 'assign_metric':
      return {
        assignedMetricId: assignedMetricId?.measurement_id,
        assignedMetricName,
        mappingTypeLabel: 'Assign metric',
        mappingType: assignedMetric?.mappingType,
      };
    case 'custom_conversions':
      return {
        assignedMetricId: assignedMetric?.metricId,
        assignedMetricName,
        mappingTypeLabel: 'Custom conversions',
        mappingType: assignedMetric?.mappingType,
      };
    case 'utm_tracking':
      return {
        assignedMetricId: null,
        assignedMetricName: 'UTM tracking',
        mappingTypeLabel: 'UTM tracking',
        mappingType: assignedMetric?.mappingType,
      };
    case 'ignore_mapping':
      return {
        assignedMetricId: null,
        assignedMetricName: 'No metric',
        mappingTypeLabel: 'Ignore mapping',
        mappingType: assignedMetric?.mappingType,
      };
    default:
      return {
        assignedMetricId: null,
        assignedMetricName: '',
        mappingTypeLabel: '',
        mappingType: null,
      };
  }
};
