import { useContentRuleQuery } from '../../../graphql/portfolioRules/queryContentRules';
import { useRouteMatch } from 'react-router';
import NoDataFound from '../NoDataFound';
import Typography from '../../../components/Typography';
import { nexyColors } from '../../../theme';
import React, { useState } from 'react';
import Button from '../../../components/Button';
import { useSidePanelState } from '../../../components/SidePanel';
import CreateOrUpdatePortfolioRule from '../components/Content/CreateOrUpdatePortfolioRule';
import { usePortfolio } from '../../../context/PortfolioProvider';
import { useFunnelStepsV2Query } from '../../../graphql/funnelSteps/queryFunnelSteps';
import { NexoyaContentRule } from '../../../types';
import Spinner from '../../../components/Spinner';
import { extractFunnelSteps } from '../../../utils/funnelSteps';
import { useCreateContentRuleMutation } from '../../../graphql/portfolioRules/mutationCreateContentRule';
import { useUpdateContentRuleMutation } from '../../../graphql/portfolioRules/mutationUpdateContentRule';
import { ContentRuleCard } from './ContentRuleCard';

const ContentRules = () => {
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);
  const { data: contentRulesData, loading } = useContentRuleQuery({ portfolioId });
  const contentRules = contentRulesData?.portfolioV2?.contentRules;
  const [funnelSteps, setFunnelSteps] = useState([]);

  const {
    selectedFunnelStep: { setSelectedFunnelStep },
  } = usePortfolio();

  const [createContentRule, { loading: loadingCreate }] = useCreateContentRuleMutation({ portfolioId });
  const [updateContentRule, { loading: loadingUpdate }] = useUpdateContentRuleMutation({ portfolioId });

  useFunnelStepsV2Query({
    portfolioId,
    onCompleted: (data) => {
      if (data?.portfolioV2?.funnelSteps?.length) {
        setSelectedFunnelStep({
          title: data.portfolioV2.funnelSteps[0]?.title,
          funnel_step_id: data.portfolioV2.funnelSteps[0].funnelStepId,
          type: data.portfolioV2.funnelSteps[0].type,
        });
        const { otherFunnelSteps } = extractFunnelSteps(data?.portfolioV2?.funnelSteps);
        setFunnelSteps(otherFunnelSteps);
      }
    },
  });

  // State to keep track of the content rule being edited
  const [contentRuleToEdit, setContentRuleToEdit] = useState<NexoyaContentRule>(null);

  const { isOpen: isOpenCreateContentRule, toggleSidePanel: toggleCreateContentRuleSidepanel } = useSidePanelState();

  // Function to close the side panel and reset editing state
  const closeCreateContentRuleSidepanel = () => {
    toggleCreateContentRuleSidepanel();
    setContentRuleToEdit(null);
  };

  // Handler for editing a content rule
  const handleEditContentRule = (contentRule: NexoyaContentRule) => {
    setContentRuleToEdit(contentRule);
    toggleCreateContentRuleSidepanel();
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Typography style={{ color: nexyColors.neutral900 }} variant="h3">
            Content rules
          </Typography>
          <Typography style={{ color: nexyColors.neutral400, marginTop: 8 }} variant="paragraph">
            Filter contents into content rules for bulk metric assignment.
          </Typography>
        </div>
        <div className="flex h-full gap-2">
          <Button onClick={toggleCreateContentRuleSidepanel} color="primary" variant="contained">
            Create content rule
          </Button>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        {loading ? (
          <Spinner />
        ) : !contentRules?.length ? (
          <NoDataFound
            style={{ height: 200 }}
            title="You don't have any content rules created yet"
            subtitle="Create a content rule to get started by pressing the green button above"
          />
        ) : (
          contentRules.map((contentRule) => (
            <ContentRuleCard
              key={contentRule.contentRuleId}
              handleEditContentRule={handleEditContentRule}
              funnelSteps={funnelSteps}
              contentRule={contentRule}
            />
          ))
        )}
      </div>
      {isOpenCreateContentRule ? (
        <CreateOrUpdatePortfolioRule
          config={{
            type: 'content-rule',
            labels: {
              name: 'Content rule',
              saveButton: 'Save content rule',
            },
            updateMutation: updateContentRule,
            createMutation: createContentRule,
          }}
          loading={{
            create: loadingCreate,
            update: loadingUpdate,
          }}
          portfolioId={portfolioId}
          isOpen={isOpenCreateContentRule}
          toggleSidePanel={closeCreateContentRuleSidepanel}
          rule={contentRuleToEdit}
        />
      ) : null}
    </div>
  );
};

export default ContentRules;
